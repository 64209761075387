<template>
    <div class="card task-group">
        <div style="padding:10px 5px;">
            {{ nomenclatureName }}

            <div style="display:flex;justify-content:space-between;margin:10px 0">
                <span v-html="markupPreset" style="color:#000099;font-size:12px;"></span>
                <span style="color:#336600;">{{ count }} {{ measureUnitName }}</span>
            </div>

            <div style="display:flex;align-items:center;">
                <div :class="clockClass" style="margin-right:12px;font-size:18px;">
                    <i class="fas fa-clock"></i>
                </div>

                <div class="task-time" :class="'state-' + productionTask.state">
                    <div style="padding:4px;display:inline-block;">{{ productionStartAt }} – {{ productionEndAt }}</div>
                    <div style="padding:4px;display:inline-block;border-left:1px solid;">{{ productionPlanDate }}</div>
                </div>
            </div>
        </div>

        <table class="table table-bordered table-xxxs">
            <tbody>
                <tr style="background:#f5f5f5;">
                    <td colspan="3" class="text-center" v-b-modal="`` + printLabelKey" style="cursor:pointer;">
                        печать этикеток
                    </td>
                </tr>
            </tbody>
        </table>

        <b-modal
            centered
            hide-footer
            :id="printLabelKey"
            :ref="printLabelKey"
            class="print-labels-modal"
            @hide="actionsAfterHideModalPrintLabels()"
        >
            <div class="errors-output" v-if="errorLabelsPrint !== null">
                <b>Ошибка: {{ errorLabelsPrint }}</b>
            </div>
            <div style="text-align:center;margin-top:20px;">
                <label style="margin-bottom:10px">Выберите принтер для печати этикетки:</label>
                <div>
                    <b-form-select v-model="selectedTypeOfPrinters" :options="typesOfPrinters"></b-form-select>
                </div>
            </div>
            <div style="text-align:center;">
                <label style="margin-bottom:10px">Выберите тип этикетки:</label>
                <div class="type-of-labels-wrapper">
                    <div class="type-of-labels-icon">
                        <i class="fas fa-circle" v-if="selectedTypeOfLabels === 'round'"></i>
                        <i class="fas fa-band-aid" v-else-if="selectedTypeOfLabels === 'tureen'"></i>
                        <i class="fas fa-square" v-else-if="selectedTypeOfLabels === 'rectangle'"></i>
                        <i
                            class="fas fa-wheelchair"
                            style="color:#900;"
                            v-else-if="selectedTypeOfLabels === 'round_robinbobin'"
                        ></i>
                        <i
                            class="fas fa-blind"
                            style="color:#900;"
                            v-else-if="selectedTypeOfLabels === 'rectangular_robinbobin'"
                        ></i>
                        <i class="fas fa-times" style="color:#900;" v-else></i>
                    </div>
                    <div class="type-of-labels-select">
                        <b-form-select v-model="selectedTypeOfLabels" :options="typesOfLabels">
                            <template #first>
                                <option :value="null" disabled>Выберите тип</option>
                            </template>
                        </b-form-select>
                    </div>
                </div>
            </div>
            <div style="text-align:center;margin-top:20px;">
                <label style="margin-bottom:10px">Введите количество этикеток:</label>
                <input
                    type="number"
                    class="form-control"
                    placeholder="Введите количество"
                    v-model="numberOfRequiredLabels"
                />
            </div>
            <div style="margin-top:30px;text-align:right;">
                <button class="btn btn-primary" @click="labelsPrint()">Отправить на печать</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import moment from 'moment';

    import { PRINT_QUEUES } from '../../utils/endpoints';

    const NOVEXX = 'novexx';
    const ZEBRA_ZT410 = 'zebra_zt410';
    const RESERVED = 'reserved';

    const CIRCLE = 'round';
    const TUREEN = 'tureen';
    const RECTANGLE = 'rectangle';
    const ROUND_ROBINBOBIN = 'round_robinbobin';
    const RECTANGULAR_ROBINBOBIN = 'rectangular_robinbobin';

    export default {
        name: "LabelingItem",

        props: {
            productionTask: {
                required: true,
                type: Object
            },
            productionPlanMarkupPreset: {
                required: true,
                type: Object
            }
        },

        data() {
            return {
                errorLabelsPrint: null,
                selectedTypeOfLabels: null,
                numberOfRequiredLabels: null,
                selectedTypeOfPrinters: NOVEXX,
            };
        },

        computed: {
            key() {
                return this.productionTask.id + '-' + this.productionPlanMarkupPreset.id;
            },
            printLabelKey() {
                return 'print-label-' + this.key;
            },

            typesOfPrinters() {
                return [
                    { value: NOVEXX, text: 'Novexx' },
                    { value: ZEBRA_ZT410, text: 'Zebra ZT410' },
                    { value: RESERVED, text: 'Резервный' }
                ];
            },
            typesOfLabels() {
                return [
                    { value: RECTANGLE, text: 'Прямоугольная' },
                    { value: CIRCLE, text: 'Круглая' },
                    { value: TUREEN, text: 'Супница' },
                    { value: ROUND_ROBINBOBIN, text: 'Круглая (Робин Бобин)' },
                    { value: RECTANGULAR_ROBINBOBIN, text: 'Прямоугольная (Робин Бобин)' }
                ];
            },

            markupPreset() {
                let mealDay = this.productionPlanMarkupPreset.markupPreset.mealDay;
                let dayOfWeek = this.productionPlanMarkupPreset.markupPreset.dayOfWeek;
                let nomenclatureName = this.productionPlanMarkupPreset.markupPreset.name;
                let productionLine = this.productionPlanMarkupPreset.markupPreset.productionLine;
                let cityName = this.productionPlanMarkupPreset.markupPreset.city;

                let markupPreset = 'Линейка: <strong>' + productionLine +
                    '</strong>; День приема: <strong>' + this.getWeekDayInStrType( dayOfWeek ) +
                    '</strong>; Номер приема: <strong>' + mealDay + '</strong>;';

                if ( cityName !== null ) {
                    markupPreset += ' <strong>' + cityName + '</strong>;';
                }

                if ( nomenclatureName !== null ) {
                    markupPreset += '<br/><strong>' + nomenclatureName + '</strong>;';
                }

                return markupPreset;
            },

            operationName() {
                return this.productionTask.operation.name;
            },
            nomenclatureName() {
                return this.productionTask.nomenclature.name;
            },
            measureUnitName() {
                return this.productionTask.nomenclature.measureUnit.name;
            },
            count() {
                return this.productionPlanMarkupPreset.count.toFixed( 3 );
            },
            productionEndAt() {
                return moment( this.productionTask.productionEndAt ).format( 'HH:mm' );
            },
            productionStartAt() {
                return moment( this.productionTask.productionStartAt ).format( 'HH:mm' );
            },
            productionPlanDate() {
                return moment( this.productionTask.productionPlan.date ).format( 'DD' );
            },

            clockClass() {
                if ( this.productionTask.isLate ) {
                    return 'late';
                }

                if ( this.productionTask.isSoon ) {
                    return 'soon';
                }

                return '';
            }
        },

        methods: {
            getWeekDayInStrType( dayOfWeek ) {
                let days = [ 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС' ];

                return days[ dayOfWeek - 1 ];
            },

            validateLabelsPrint() {
                if ( this.selectedTypeOfLabels === null ) {
                    this.errorLabelsPrint = 'Необходимо выбрать тип этикетки';

                    return false;
                }

                if ( this.numberOfRequiredLabels === null ) {
                    this.errorLabelsPrint = 'Необходимо ввести количество этикеток';

                    return false;
                }

                if ( this.numberOfRequiredLabels <= 0 ) {
                    this.errorLabelsPrint = 'Количество этикеток должно быть больше 0';

                    return false;
                }

                return true;
            },

            labelsPrint() {
                if ( this.validateLabelsPrint() === true ) {
                    let params = {
                        new_rules: false,
                        labels_type: this.selectedTypeOfLabels,
                        printer_type: this.selectedTypeOfPrinters,
                        number_of_labels: this.numberOfRequiredLabels,
                        production_task: { id: this.productionTask },
                        production_plan_markup_preset: { id: this.productionPlanMarkupPreset.id },
                    };

                    this.$http
                        .post( PRINT_QUEUES + '/append-to/front', params )
                        .then(
                            () => {
                                this.$refs[ this.printLabelKey ].hide();
                                this.actionsAfterHideModalPrintLabels();
                            },
                            response => {
                                if ( response.body.errors.length ) {
                                    this.errorLabelsPrint = response.body.errors[ 0 ];
                                    return null;
                                }

                                this.errorLabelsPrint = 'Неизвестная ошибка во время создания заданий на печать';
                            }
                        );
                }
            },

            actionsAfterHideModalPrintLabels() {
                this.errorLabelsPrint = null;
                this.selectedTypeOfLabels = null;
                this.numberOfRequiredLabels = null;
                this.selectedTypeOfPrinters = NOVEXX;
            },
        }
    }
</script>

<style scoped lang="sass">
    .type-of-labels-wrapper
        display: flex
        align-items: center
        justify-content: space-between

    .type-of-labels-icon
        width: 5%
        display: flex
        justify-content: center

    .type-of-labels-select
        width: 95%
        display: flex

    .errors-output
        color: #900
        text-align: center
        margin-bottom: 15px

    .card.task-group
        width: 100%
        border-bottom: 2px solid black

    .task-group .late
        color: red

    .task-group .soon
        color: mediumseagreen

    .task-group .task-time
        border: 1px solid black
        border-radius: 5px
        font-size: 12px
        white-space: nowrap
        margin-right: 10px
</style>

<style scoped>
    .task-group table,
    .task-group >>> table {
        margin-bottom: 0;
    }

    .task-group .state-started,
    .task-group >>> .state-started {
        background: #FFD966;
    }

    .task-group .state-finished,
    .task-group >>> .state-finished {
        background: #D5E8D4;
    }

    .task-group .task-table >>> thead {
        display: none !important;
    }

    >>> .task-group td {
        padding: .4rem;
    }

    >>> .print-labels-modal .modal-body {
        padding: .75rem 0.5rem;
    }

    >>> .print-labels-modal .modal-header {
        padding: .5rem;
    }
</style>
