<template>
    <div>
        <div role="tablist">
            <b-card no-body class="mb-1 text-center">
                <b-card-header role="tab" class="p-1 filters-wrapper" header-tag="header">
                    <div class="filters-value">
                        <strong>
                            <template v-if="filters.dateByShift">{{ formattedFilterDateByShift }}</template>
                            <template v-if="filters.dateByShift && filters.shift"> / </template>
                            <template v-if="filters.shift">{{ filters.shift.name }}</template>
                        </strong>
                    </div>
                    <div>
                        <b-button variant="primary" size="lg" @click="applyFilters()">
                            <i class="fas fa-sync"></i>
                        </b-button>

                        <div v-b-toggle.accordion1 class="dropdown-toggle btn btn-secondary btn-lg">Фильтр</div>
                    </div>
                </b-card-header>

                <b-collapse id="accordion1">
                    <b-card-body>
                        <b-row>
                            <b-col md="4">
                                <b-form-group description="План на дату">
                                    <input type="date" class="form-control" v-model="filters.dateByShift" />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group description="Смена">
                                    <shift-input v-model="filters.shift"></shift-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group description="Показывать завершенные">
                                    <input type="checkbox" class="form-control" v-model="filters.showFinished" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <div class="card-columns" style="column-count:1;">
                <template v-for="productionTask in preparedProductionTasks">
                    <labeling-item
                        v-if="productionPlanMarkupPreset.markupPreset"
                        :key="productionTask.id + '-' + productionPlanMarkupPreset.id"
                        v-for="productionPlanMarkupPreset in productionTask.productionPlan.productionPlanMarkupPreset"

                        :productionTask="productionTask"
                        :productionPlanMarkupPreset="productionPlanMarkupPreset"
                    ></labeling-item>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import forEach from 'lodash/forEach';

    import LabelingItem from './LabelingItem';
    import ShiftInput from "../_common/ShiftInput";

    import lsfMixin from '../../utils/localStorageFilterMixin';
    import { PRODUCTION_TASKS_ENDPOINT } from '@utils/endpoints';

    const STATE_NONE = 'none';
    const STATE_STARTED = 'started';
    const STATE_FINISHED = 'finished';

    const FILTERS_PATTERN = {
        shift: null,
        dateByShift: null,
        showFinished: false
    };

    export default {
        name: "LabelingList",

        mixins: [ lsfMixin ],
        components: { ShiftInput, LabelingItem },

        data() {
            return {
                productionTasks: []
            };
        },

        computed: {
            preparedProductionTasks() {
                let preparedProductionTasks = this.productionTasks;

                if ( !this.filters.showFinished ) {
                    preparedProductionTasks = preparedProductionTasks.filter( task => task.state !== STATE_FINISHED );
                }

                return preparedProductionTasks;
            },
            formattedFilterDateByShift() {
                return moment( this.filters.dateByShift ).format( 'DD.MM.YYYY' );
            },
            preparedConditions() {
                let conditions = {};

                forEach( this.getFilters, function ( filter, key ) {
                    if ( filter ) {
                        switch ( key ) {
                            case 'dateByShift':
                                conditions[ 'date' ] = moment( filter ).format( 'YYYY-MM-DD' );
                                break;
                            case 'shift':
                                conditions[ 'shift_id' ] = filter.id;
                                break;
                        }
                    }
                } );

                conditions[ 'storage.packing' ] = true;

                return conditions;
            },
        },

        watch: {
            filters: {
                deep: true,
                handler( value ) {
                    this.watchFilters( value );
                }
            }
        },

        methods: {
            applyFilters() {
                this.productionTasks = [];
                let params = {filters: this.preparedConditions, with: ['plan.markupPresets']};

                if ( !params[ 'date' ] || !params[ 'shift_id' ] ) {
                    return null;
                }

                this.$http
                    .get( PRODUCTION_TASKS_ENDPOINT, { params } )
                    .then( response => this.productionTasks = response.data.data );
            }
        },

        created() {
            this.initFilters( this, FILTERS_PATTERN );
        }
    }
</script>

<style scoped lang="sass">
    .card
        margin-bottom: 0 !important

    .filters-wrapper
        display: flex
        align-items: center
        justify-content: space-between

    .filters-value
        display: flex
        font-size: 18px
        padding-left: 10px
</style>
