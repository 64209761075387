import { render, staticRenderFns } from "./LabelingItem.vue?vue&type=template&id=6b032594&scoped=true&"
import script from "./LabelingItem.vue?vue&type=script&lang=js&"
export * from "./LabelingItem.vue?vue&type=script&lang=js&"
import style0 from "./LabelingItem.vue?vue&type=style&index=0&id=6b032594&scoped=true&lang=sass&"
import style1 from "./LabelingItem.vue?vue&type=style&index=1&id=6b032594&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b032594",
  null
  
)

export default component.exports